/**
 * Converts an enum type into an array of objects with `label` and `value` properties.
 * The `label` is derived from the enum key, with underscores replaced by spaces,
 * and the first letter capitalized.
 * The `value` is the corresponding enum value.
 * The resulting array is sorted by `label` in ascending order.
 */
export default function enumToOptions<T>(enumType: T, capitalFirstLetter: boolean = true): {label: string, value: T}[] {
  return Object.entries(enumType)
    .map(([key , value]) => ({
      label: capitalFirstLetter ?
        key.replace(/_/g, ' ')
          .toLowerCase()
          .replace(/^\w/, c => c.toUpperCase()) : key,
      value
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
